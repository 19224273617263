
.working_hours_container{
    max-width: 320px;
    padding: 40px 20px;
    
}
.working_hours{
    font-size: 21px;
    padding: 40px 20px;
    text-align: initial;
    border-spacing: 0 5px;
    max-width: 320px;
  
}

.working_hours td {
    padding: 7px 20px
}
.working_hours .current{
    background-color: #00b3ee54;
}
.working_hours th{
    text-decoration: underline;
    padding-bottom: 10px;
}