.Footer{
    background-color: var(--accent-color);
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    a{
        color: white;
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 15px;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
        &:last-of-type{
            margin-bottom: 0;
        }
    }
}