

* , *::after, *::before{
    box-sizing: border-box;
}
html , body{
    padding: 0;
    margin: 0;
    width: 100%;
    position: relative;
    font-family: 'Rubik', sans-serif;
}

.overflow_hidden{
    overflow: hidden;
}

:root {
    --main-color: #00B3EE;
    --accent-color: #1E4384;
    --background-color: white;
    --accent-background-color: #ECEDEF;
    --error: #ff0033;
    --success: #2ecc71;
  }

  .loader{
    mask: url('~assets/loader.svg');
    mask-repeat: no-repeat;
    background-color: var(--accent-color);
    mask-size: contain;
    mask-position: center;
    color: transparent;
    cursor: wait;
    margin: auto;
}


  
.button{
    display: inline-block;
    padding: 0.3em 1em;
    border-radius: 10px;
    background-color: var(--main-color);
    color: white;
    font-weight: 700;
    font-size: 16px;
    border: none;
    cursor: pointer;
    outline: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn_secondary{
    background-color: var(--accent-color);
}

.btn_round{
    padding: 0.2rem 1rem;
    border-radius: 50%;
}

.btn_large{
    font-size: 25px;
}

.btn_refresh{
    mask: url('~assets/refresh.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    padding: 0;
    min-height: 30px;
    min-width: 30px;

    &.animating{
        animation: refresh 1s ease-in-out infinite;
        cursor: wait;
    }
}

@keyframes refresh {
    from {transform: rotate(0);}
    to {transform: rotate(360deg);}
  }


input, select{
    font-family: 'Rubik';
}


.title{
    color: var(--accent-color);
    font-size: 40px;
    font-weight: 500;
}

.container{
    max-width: 1920px;
    width: 100%;
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    margin: auto;
    padding: 0 20px;
}


@media only screen and (max-width: 1199px) {
    .container{ max-width: 768px;} 

}
@media only screen and (max-width: 767px) {
    .container{ max-width: none;} 
    .btn_large{
        font-size: 20px;
    }
}